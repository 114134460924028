import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { Container, Row, Col } from "react-bootstrap";
import parse from "html-react-parser";

import Layout from "../components/Layout";
import Seo from "../components/Seo";
import Hero from "../components/Hero";
import ImgFading from "../components/ImgFading";

const StudioTemplate = function ({ data: { appSetting, page } }) {
  return (
    <Layout appSettingLocale={appSetting}>
      <Hero
        title={page.hero?.title ? page.hero.title : page.title}
        description={page.hero?.description && page.hero.description}
        background={
          page.hero?.background && page.hero.background.localFile.publicURL
        }
      />
      <section className="ttm-row-page">
        <Container>
          <Row>
            <Col lg={6} className="mb-5 mb-lg-0">
              {parse(page.content.data.content)}
            </Col>
            <Col lg={6} className="text-center text-lg-end mb-3 mb-lg-0">
              <ImgFading>
                {page.images &&
                  page.images.map(({ id, localFile }) => (
                    <GatsbyImage
                      key={`images-${id}`}
                      className="rounded shadow"
                      image={localFile.childImageSharp.gatsbyImageData}
                      alt={localFile.name}
                    />
                  ))}
              </ImgFading>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  );
};

StudioTemplate.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  location: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default StudioTemplate;

export const Head = ({
  location,
  data: {
    page: { title, localizations },
  },
}) => (
  <Seo
    title={title}
    localizations={localizations.data}
    pathname={location.pathname}
  />
);

export const pageQuery = graphql`
  query ($id: String!, $locale: String!) {
    page: strapiStudio(id: { eq: $id }) {
      locale
      localizations {
        data {
          attributes {
            locale
            slug
          }
        }
      }
      title
      hero {
        title
        description
        background {
          name
          localFile {
            publicURL
          }
        }
      }
      content {
        data {
          content
        }
      }
      images {
        id
        localFile {
          name
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    appSetting: strapiAppSetting(locale: { eq: $locale }) {
      headerNav {
        strapi_json_value {
          label
          url
          subMenu {
            label
            url
          }
        }
      }
      footerNav {
        strapi_json_value {
          label
          subMenu {
            label
            url
          }
        }
      }
      footerContactTitle
      footerContactContent {
        data {
          footerContactContent
        }
      }
      copyrightLinks {
        strapi_json_value {
          label
          url
        }
      }
    }
  }
`;
